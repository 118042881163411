import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Header Section */
export interface KenticoHeaderSection extends KontentItem {
  heading: KontentTextElement
  description: KontentTextElement
  background_desktop: KontentAssetElement
  background_mobile: KontentAssetElement
  image_or_video: KontentAssetElement
  embedded_video: KontentTextElement
}

export class HeroSection {
  heading: string
  description: string
  backgroundDesktop: ImageItem
  backgroundMobile: ImageItem
  imageOrVideo: ImageItem
  embeddedVideo: string

  constructor(data: KenticoHeaderSection) {
    this.heading = _get(data, 'heading.value', '')
    this.description = _get(data, 'description.value', '')
    this.backgroundDesktop = _get(data, 'background_desktop.value[0]', null)
    this.backgroundMobile = _get(data, 'background_mobile.value[0]', null)
    this.imageOrVideo = _get(data, 'image_or_video.value[0]', null)
    this.embeddedVideo = _get(data, 'embedded_video.value', null)
  }
}

export const heroSectionFragment = graphql`
  query HeroSectionQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_hero_section {
                elements {
                  ...kontent_item_hero_section_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`
export const heroSectionElementsFragment = graphql`
  fragment kontent_item_hero_section_elementsFragment on kontent_item_hero_section_elements {
    background_desktop {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    background_mobile {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    image_or_video {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    description {
      value
    }
    heading {
      value
    }
    embedded_video {
      value
    }
  }
`

export const useHeroSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(heroSectionFragment)
  const heroSectionData: KenticoHeaderSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new HeroSection(heroSectionData)
}
