import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { IsMobileContext, LanguageContext } from '../common/Context'
import { AnimationService } from '../common/AnimationService'
import { BlogCategory } from '../common/typings/enums'

//components
import Layout from '../components/Layout'
import BlogArticlesSection from '../components/BlogArticlesSection'
import FloatingButton from '../components/FloatingButton'
import Footer from '../components/Footer'
import ImageVideoSection from '../components/ImageVideoSection'
import ClientsSection from '../components/ClientsSection'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Tags from '../components/Tags'

//models, hooks
import { useSeoData } from '../components/SeoComponent/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'
import { useBlogArticlesSectionData, BlogArticlesSection as BlogArticlesSectionClass } from '../components/BlogArticlesSection/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useHeroSectionData, HeroSection as HeroSectionClass } from '../components/HeroSection/model'

//other
import withSSR from '../hoc/withSSR'
import './blog-category.scss'
import { PageContext } from 'gatsby-plugin-react-i18next/dist/types'

const BlogCategoryPage: React.FC<PageProps> = ({ location: { pathname }, pageContext }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const pageCodename = 'blog_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const blogArticlesSectionData = useBlogArticlesSectionData(language)
  const blogCategoriesSet = new Set<string>()
  blogArticlesSectionData.blogArticles.forEach(article => article.category && blogCategoriesSet.add(article.category))
  const blogCategories = [BlogCategory.ALL, ...[...blogCategoriesSet].sort()]
  const pageData = {
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
    clientsSectionData: useClientsSectionData(language, pageCodename),
    imageVideoSectionData: useImageVideoSectionData(language, pageCodename),
    heroSectionData: useHeroSectionData(language, pageCodename),
    blogArticlesSectionData,
    blogCategories,
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <BlogCategoryPageTemplateWithSSR pageData={pageData} pageContext={pageContext} />
    </Layout>
  )
}

interface KenticoBlogCategories {
  'ecommerce': string
  'business': string,
  'user-experience': string,
  'user-interface': string
}

interface BlogCategoryProps extends PageContext {
  blogCategory: string
}

interface BlogCategoryPageProps {
  isMobile: boolean
  pageContext: Partial<BlogCategoryProps>
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    clientsSectionData: ClientsSectionClass
    imageVideoSectionData: ImageVideoSectionClass
    blogCategories: string[]
    blogArticlesSectionData: BlogArticlesSectionClass
    heroSectionData: HeroSectionClass
  },
}

const BlogCategoryPageTemplate: React.FC<BlogCategoryPageProps> = ({
  isMobile,
  pageContext,
  pageData: {
    linksGridData,
    navbarData,
    clientsSectionData,
    imageVideoSectionData,
    blogCategories,
    blogArticlesSectionData,
    heroSectionData,
  },
}) => {
  const [selectedBlogCategory, setSelectedBlogCategory] = React.useState<string>(BlogCategory[pageContext.blogCategory as keyof KenticoBlogCategories])

  const blogsFilteredByCategory = selectedBlogCategory !== BlogCategory[pageContext.blogCategory as keyof KenticoBlogCategories]
    ? blogArticlesSectionData.blogArticles
    : blogArticlesSectionData.blogArticles.filter(article => article.category === BlogCategory[pageContext.blogCategory as keyof KenticoBlogCategories])
  const filteredBlogArticlesSectionData = { blogArticles: blogsFilteredByCategory }

  const tagsRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (tagsRef.current) {
      if (!AnimationService.isInView(tagsRef.current)) {
        AnimationService.slideUp(tagsRef.current)
      }
    }
  }, [])

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="BlogCategory">
        <HeroSection {...heroSectionData} headingColumns={{ desktop: 6, mobile: 12 }} descriptionColumns={{ desktop: 6, mobile: 12 }} />
        <section className="TagsSection GlobSectPadSpacing mx-mobile-xs lg:mx-m" ref={tagsRef}>
          <div className="SpacingAdjust pt-mobile-s lg:pt-s"></div>
          <Tags
            tags={blogCategories}
            initiallyActive={BlogCategory[pageContext.blogCategory as keyof KenticoBlogCategories]}
            onChange={() => setSelectedBlogCategory(BlogCategory[pageContext.blogCategory as keyof KenticoBlogCategories])}
            isItLink={true}
          />
        </section>
        <BlogArticlesSection {...filteredBlogArticlesSectionData} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const BlogCategoryPageTemplateWithSSR = withSSR<BlogCategoryPageProps>(BlogCategoryPageTemplate)

export default BlogCategoryPage

export const query = graphql`
  query($language: String!, $blogCategory: String) {
    locales: allLocale(filter: {ns: {in: ["common","blog"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allKontentItemBlogArticle(
      filter: {elements: {blog_category: {value: {elemMatch: {name: {eq: $blogCategory}}}}}}
    ) {
      nodes {
        elements {
          blog_category {
            value {
              name
            }
          }
        }
      }
    }
  }
`
