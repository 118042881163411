import * as React from 'react'
import { IsMobileContext } from '../../common/Context'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { HeroSection as HeroSectionClass } from './model'
import { AnimationService } from '../../common/AnimationService'
import { ImageQuality } from '../../common/typings/enums'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import './style.scss'

interface HeroSectionProps extends HeroSectionClass {
  headingColumns: {
    desktop: number
    mobile: number
  }
  descriptionColumns: {
    desktop: number
    mobile: number
  }
  headingClass?: string
}

const HeroSection: React.FC<HeroSectionProps> = ({
  heading,
  description,
  backgroundDesktop,
  backgroundMobile,
  imageOrVideo,
  children,
  headingColumns,
  descriptionColumns,
  headingClass,
  embeddedVideo
}) => {

  const { isMobile } = React.useContext(IsMobileContext)
  const textRef = React.useRef<HTMLHeadingElement>(null)
  const childrenRef = React.useRef<HTMLDivElement>(null)
  const imageOrVideoRef = React.useRef<HTMLDivElement>(null)
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])
  const mobileColumnSpans = [
    '', 'col-span-1', 'col-span-2', 'col-span-3', 'col-span-4', 'col-span-5', 'col-span-6',
    'col-span-7', 'col-span-8', 'col-span-9', 'col-span-10', 'col-span-11', 'col-span-12',
  ]
  const desktopColumnSpans = [
    '', 'lg:col-span-1', 'lg:col-span-2', 'lg:col-span-3', 'lg:col-span-4', 'lg:col-span-5', 'lg:col-span-6',
    'lg:col-span-7', 'lg:col-span-8', 'lg:col-span-9', 'lg:col-span-10', 'lg:col-span-11', 'lg:col-span-12',
  ]

  React.useEffect(() => {
    if (textRef.current && !AnimationService.isInView(textRef.current)) {
      AnimationService.slideUp(textRef.current)
    }
    if (!AnimationService.isInView(childrenRef.current)) {
      AnimationService.slideUp(childrenRef.current)
    }
    if (imageOrVideoRef.current && !AnimationService.isInView(imageOrVideoRef.current)) {
      AnimationService.slideUp(imageOrVideoRef.current)
    }
  }, [])

  const isVideo = (asset: ImageItem) => asset?.type?.includes('video')
  const isImage = (asset: ImageItem) => asset?.type?.includes('image')
  const backgroundAsset = isMobile ? backgroundMobile : backgroundDesktop

  return (
    <section className="HeroSection GlobSectPadSpacing relative">
      <div className={`SpacingAdjust ${heading || description || children ? 'pt-mobile-l lg:pt-l' : ''}`}></div>
      <div className="grid grid-cols-12 gap-x-m px-mobile-xs lg:px-m">
        {heading && (
          <h1 className={`${headingClass ? headingClass : 'FontXL'} z-10 
            ${mobileColumnSpans[headingColumns.mobile]} ${desktopColumnSpans[headingColumns.desktop]}`}
          >
            {heading}
          </h1>
        )}
        {description && (
          <p ref={textRef} className={`FontL row-start-2 z-10 mt-mobile-s lg:mt-s
            ${mobileColumnSpans[descriptionColumns.mobile]} ${desktopColumnSpans[descriptionColumns.desktop]}`}
          >
            {description}
          </p>
        )}
        <div ref={childrenRef} className="col-span-12">
          {children}
        </div>
      </div>
      {(isImage(backgroundAsset)) && (
        <picture className="absolute w-full h-full top-0 left-0 -z-10 object-cover overflow-hidden">
          <ImageElement
            style={{ minHeight: '100%', minWidth: '100%' }}
            layout="fullWidth"
            image={backgroundAsset}
            alt={backgroundAsset.description || backgroundAsset.name}
            loading="eager"
            options={imageQualityOptions}
          />
        </picture>
      )}
      {(isVideo(backgroundAsset)) && (
        <video autoPlay muted loop playsInline className="absolute w-full h-full top-0 left-0 -z-10 object-cover">
          <source
            src={backgroundAsset.url}
            type="video/mp4"
          />
        </video>
      )}
      {isImage(imageOrVideo) && (
        <div ref={imageOrVideoRef} className={`${heading || description || children ? 'mt-mobile-l lg:mt-l' : ''}`}>
          <ImageElement
            style={{ minHeight: '100%', minWidth: '100%' }}
            layout="fullWidth"
            image={imageOrVideo}
            alt={imageOrVideo.description || imageOrVideo.name}
            loading="eager"
            options={imageQualityOptions}
          />
        </div>
      )}
      {isVideo(imageOrVideo) && (
        <div ref={imageOrVideoRef} className={`lg:px-m ${heading || description || children ? 'mt-mobile-l lg:mt-l' : ''}`}>
          <video autoPlay muted loop playsInline className="overflow-hidden flex object-cover w-full">
            <source
              src={imageOrVideo.url}
              type="video/mp4"
            />
          </video>
        </div>
      )}
      {embeddedVideo && (
        <iframe
          className={`EmbeddedVideo w-full h-mobile-xxl lg:h-xxxxl ${heading || description || children ? 'mt-mobile-l lg:mt-l' : ''}`}
          src={embeddedVideo}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          title="youtube video"
          allowFullScreen
        ></iframe>
      )}
      {backgroundAsset && <div className="h-mobile-m lg:h-0"></div>}
    </section>
  )
}

export default HeroSection
